import { Modal, Tree } from 'antd';
import { DataNode } from 'antd/es/tree';
import cn from 'classnames';
import React from 'react';

import land1_checked from '@/assets/images/islands/islands1-checked@2x.png';
import { useTranslation } from '@/i18n';
import { useDeviceSize } from '@/utils/hooks/useDeviceSize';

import styles from './FirstIsLandModal.module.less';

interface FirstIsLandModalProps {
  className?: string;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
}

export function FirstIsLandModal(props: FirstIsLandModalProps) {
  const { className, isOpen, setIsOpen } = props;
  const { t } = useTranslation();
  const { isSmallDevice } = useDeviceSize(867);

  const treeData: DataNode[] = [
    {
      title: (
        <div className=" text-[16px] leading-[180%]">
          {t('library_content1')}
        </div>
      ),
      key: '0-0',

      children: [
        {
          title: (
            <div
              className={cn(
                'rounded-[20px] bg-[#FFFFFF66] py-4 pr-2  text-[16px] leading-[180%]',
                isSmallDevice ? 'px-2' : 'px-5'
              )}
            >
              <div className="h-full max-h-[250px] overflow-y-auto pr-2">
                <div>{t('library_content2')}</div>
                <div>{t('library_content3')}</div>
                <div>{t('library_content4')}</div>
                <div>{t('library_content5')}</div>
                <div>{t('library_content6')}</div>
                <div>{t('library_content7')}</div>
                <div>{t('library_content8')}</div>
                <div>{t('library_content9')}</div>
              </div>
            </div>
          ),
          key: '0-0-0'
        }
      ]
    },
    {
      title: (
        <div className=" text-[16px] leading-[180%]">
          {t('library_content10')}
        </div>
      ),
      key: '0-1',

      children: [
        {
          title: (
            <div
              className={cn(
                ' rounded-[20px] bg-[#FFFFFF66] py-4 pr-2  text-[16px] leading-[180%]',
                isSmallDevice ? 'px-2' : 'px-5'
              )}
            >
              <div className="h-full max-h-[250px] overflow-y-auto pr-2">
                <div>{t('library_content11')}</div>
                <div>{t('library_content12')}</div>
              </div>
            </div>
          ),
          key: '0-1-0'
        }
      ]
    },
    {
      title: (
        <div className=" text-[16px] leading-[180%]">
          {t('library_content13')}
        </div>
      ),
      key: '0-2',

      children: [
        {
          title: (
            <div
              className={cn(
                'rounded-[20px] bg-[#FFFFFF66] py-4 pr-2  text-[16px] leading-[180%]',
                isSmallDevice ? 'px-2' : 'px-5'
              )}
            >
              <div className="h-full max-h-[250px] overflow-y-auto pr-2">
                <div>{t('library_content14')}</div>
                <div>{t('library_content15')}</div>
                <div>{t('library_content16')}</div>
                <div>{t('library_content17')}</div>
              </div>
            </div>
          ),
          key: '0-2-0'
        }
      ]
    },
    {
      title: (
        <div className=" text-[16px] leading-[180%]">
          {t('library_content18')}
        </div>
      ),
      key: '0-3',

      children: [
        {
          title: (
            <div
              className={cn(
                'rounded-[20px] bg-[#FFFFFF66]  py-4  pr-2 text-[16px] leading-[180%]',
                isSmallDevice ? 'px-2' : 'px-5'
              )}
            >
              <div className="h-full max-h-[250px] w-full overflow-y-auto pr-2">
                <div>{t('library_content19')}</div>
                <div>
                  {t('library_content20')}{' '}
                  <a
                    className="text-[blue]"
                    href={t('library_content20_oslink')}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => window.open(t('library_content20_oslink'))}
                    style={{ wordBreak: 'break-all' }}
                  >
                    {t('library_content20_oslink')}
                  </a>
                </div>

                <div>{t('library_content21')}</div>
              </div>
            </div>
          ),
          key: '0-3-0'
        }
      ]
    },
    {
      title: (
        <div className=" text-[16px] leading-[180%]">
          {t('library_content22')}
        </div>
      ),
      key: '0-4',

      children: [
        {
          title: (
            <div
              className={cn(
                'rounded-[20px] bg-[#FFFFFF66] py-4 pr-2  text-[16px] leading-[180%]',
                isSmallDevice ? 'px-2' : 'px-5'
              )}
            >
              <div className="h-full max-h-[250px] overflow-y-auto pr-2">
                <div>{t('library_content23')}</div>
                <div>{t('library_content24')}</div>
                <div>{t('library_content25')}</div>
              </div>
            </div>
          ),
          key: '0-4-0'
        }
      ]
    },
    {
      title: (
        <div className=" text-[16px] leading-[180%]">
          {t('library_content26')}
        </div>
      ),
      key: '0-5',

      children: [
        {
          title: (
            <div className="max-h-[250px] overflow-y-auto rounded-[20px] bg-transparent px-5 py-4  text-[16px] leading-[180%]"></div>
          ),
          key: '0-5-0'
        }
      ]
    }
  ];

  return (
    <Modal
      className={cn(className, styles.modal1)}
      centered={true}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
    >
      <div className="relative h-full w-full font-Zpix">
        <img
          className={cn(
            'absolute   ',
            isSmallDevice
              ? 'top-[-40px] left-[-15%] h-[80px]'
              : 'top-[-70px] left-[-25%] h-[120px]'
          )}
          src={land1_checked}
          draggable={false}
        />
        <div>
          <div
            className={cn(
              'text-[#ffffff]',
              isSmallDevice
                ? 'ml-2 pt-[30px] text-[20px]'
                : 'ml-[100px] text-[32px] '
            )}
          >
            {t('library_tittle')}
          </div>
          <div
            className={cn(
              'overflow-y-auto  font-Zpix text-[20px] font-[600] text-[#000000]',
              styles.textArea,
              isSmallDevice
                ? 'mt-[30px] max-h-[400px] pr-2'
                : 'mt-[35px] max-h-[500px] px-5'
            )}
          >
            <Tree
              className={cn('select-text font-Zpix')}
              showIcon={true}
              defaultExpandAll={true}
              defaultSelectedKeys={['0-0-0']}
              // switcherIcon={<DownOutlined />}
              treeData={treeData}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
