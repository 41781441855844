import './App.less';

import { Route, Routes, useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { WOW } from 'wowjs';

import { AppLayout } from './components/AppLayout';
import Home from './pages/home';

export const wowInstance = new WOW({ live: true, offset: 0 });
wowInstance.init();

function App() {
  const { pathname } = useLocation();

  useUpdateEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <AppLayout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </AppLayout>
  );
}

export default App;
