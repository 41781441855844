export default{
  zh:{
    language:'简体中文',
    nav_dao:'DAO',
    nav_doc:'文档',
    nav_discourse: '论坛',
    nav_wallet: '连接钱包',
    
    error_balance: '账户余额不足',
    error_timeout: '交易过期，请刷新重试',

    pfpname:'X-Order {{count}}',
    pfpdesctoken0:'献给我们永远的船长！',
    pfpdesctoken10:'每10个X-Order拍卖，GrandLineDao都会保留一个X-Order，用于赠予给被证明给有梦想的“Z世代“上。',
    pfpDescTokenNull: '它被伟大航DAO的大漩涡吞噬了，也许未来某一天会有其他X-Order带领它再次出海！',

    auction_currentbid:'当前出价',
    auction_auctiontime:'拍卖结束于',
    auction_winner:'成交价格',
    auction_holder:'持有者',
    auction_bid:'出价',
    auction_settle:'结算',
    auction_bidlist:'查看所有出价',
    auction_bidview:'暂无出价',

    octopus_tittle:'章鱼引导员Paul',
    octopus_content1:'Fufufu！你将进入的是一个充满危机的新世界：极化海！',
    octopus_content2:'你曾经的认知都将在这片大海被颠覆，没有人能帮助你，能帮助你的只有你自己，你需要觉醒，打开限制器，甚至不断经历渡劫，找到同伴，建立新的秩序，才能在这片大海生存下去，找到属于你的大秘宝。',
    octopus_content3:'曾经有一位伟大的先知船长 TT 赐予了你们这群勇敢疯狂的开拓者一个名字--X-Order！',
    octopus_content4:'人们逐渐把在极化海上X-Orders共同建立的新秩序和建立的流程称之为GrandLineDao。',
    octopus_content5:'如果你真的做好准备了，那就出发启航去冒险吧！',
    octopus_content6:'再给你一点友情提醒吧：',
    octopus_content7:'· 每24小时都有一名新X-Order有资格出海，前赴后继，永不停止',
    octopus_content8:'· 每日会拍卖X-Order出海证，参与者必须展现出决心来争取这个名额',
    octopus_content9:'· X-Order共由11名不同的职业身份，分别为船长、剑客、航海少女、狙击手、厨师、医生、机器人、宠物、音乐家、舵手、美人鱼。所有角色都一样珍贵。',
    octopus_content10:'· 100% 的拍卖收入会送入名为GrandLineDao的国库',
    octopus_content11:'· 所有X-Order 是GrandLineDao的成员',
    octopus_content12:'· 每个X-Order拥有一个投票权',
    octopus_content13:'· GrandLineDao国库资金的使用由X-Orders共同治理决定',
    octopus_content14:'· X-Order可以在海贼酒馆提交提案(coming soon）',
    octopus_content15:'· 每10个X-Order出海证会进入GrandLineDao国库，用于赌在“新世代”上',

    library_tittle:'资料馆',
    library_content1:'1)  Who is  TT',
    library_content2:'某一天，这个世界的大海发生了巨变，海上所有气候、地形、甚至磁场都会随时剧变的“极化海”，环境的剧变带来新的秩序和机遇。',
    library_content3:'他是一切的缘起，是第一个预言到“极化海”出现的先知&船长。也是在极化海出现以后第一个踏上旅途的船长。',
    library_content4:'TT一直在航行中把所见所闻都分享给留在大陆上跃跃欲试的人们。',
    library_content5:'某一天，TT留下一句',
    library_content6:'“觉醒吧！打开限制器！渡劫吧！一起做梦吧！一起成为极化海上最自由的人吧，X-Orders！”',
    library_content7:'便消失在了世人的视野。人们猜测他已经找到了大秘宝or沉浸在了自己的冒险。',
    library_content8:'之后，源源不断的冒险家们受到他精神的感召出海。人们把他出海的航线就称之为GrandLineDao。',
    library_content9:'世人把这群同样具有冒险精神的冒险家们一个共同的称号—-X-Order。',
    library_content10:'2）What is GrandLineDao?',
    library_content11:'GrandLineDao 最初指的是“极化海”上TT曾经冒险过的航线。',
    library_content12:'在冒险家们（X-Order）变多了以后，人们逐渐把在极化海上X-Orders共同建立的新秩序和建立的流程称之为GrandLineDao。',
    library_content13:'3）What is X-Order？',
    library_content14:'世人把在GrandLineDao上勇敢且疯狂的开拓者群体一个共同的称号—-X-Order！',
    library_content15:'其中，X代表未知，Order代表秩序。',
    library_content16:'第一批X-Order很多都是曾经在TT船上的船员。',
    library_content17:'参考初期船员的配置，X-Order共有11种职业，分别为船长、剑客、航海少女、狙击手、厨师、医生、机器人、宠物、音乐家、舵手、美人鱼。每个职业有不同的traits，但所有角色都一样珍贵。',
    library_content18:'4）X-Order出海证的拍卖和设置',
    library_content19:'虽然大海上人人都是自由的，但能获得X-Order的称号是所有冒险家的光荣。GrandLineDao每天都会在grandlinedao.xyz拍卖一个X-Order出海证，永远持续下去。每10个X-Order拍卖，GrandLineDao都会保留一个X-Order，用于赠予给被证明给有梦想的“Z世代“上，',
    library_content20:'每个X-Order是一个ERC-721的token，所有X-Order的ERC-721 tokens 可以在这里查看：',
    library_content20_oslink:'https://opensea.io/collection/grandlinedao',
    library_content21:'因为以太坊的工作机制，每一个X-Order拍卖必须在上一个拍卖完成后主动进行设置。拍卖设计的交易包含三个事物：1）发送现在X-Order给到拍卖获胜者；2）生成下一个X-Order；3）开始下一个24小时的X-Order拍卖。',
    library_content22:'5）GrandLineDao国库',
    library_content23:'X-Order 拍卖的收益将 100% 进入 GrandLineDao 国库。',
    library_content24:'GrandLineDao Treasury 由 X-Orders 管理每个 X-Order 都是 GrandLineDao 不可或缺的成员，并且允许你在“ Polarized Sea”上建立新的秩序，每个X-Order拥有一票。',
    library_content25:'选票是不可转让的（如果你卖掉你的 X-Order，投票权也会随之消失）。但是你可以委托你的选票，这意味着只要你拥有你的 X-Order，你就可以将你的选票交由他人投票。',
    library_content26:'6）合约资料',
  },
  en: {
    language:'English',
    nav_dao:'DAO',
    nav_doc:'Docs',
    nav_discourse: 'Discourse',
    nav_wallet: 'Connect Wallet',
    
    error_balance: 'Insufficient account balance.',
    error_timeout: 'Transaction expired, please refresh and try again.',

    pfpname:'X-Order {{count}}',
    pfpdesctoken0:'To our forever captain!',
    pfpdesctoken10:'For every 10th X-Order , GrandLineDao reserves the X-Order to give to the "Z Generation" who have proven their ambitions.',
    pfpDescTokenNull: 'It was swallowed by the great maelstrom of the GrandLineDao, and perhaps one day in the future another X-Order will lead it out to sea again!',

    auction_currentbid:'Current bid',
    auction_auctiontime:'Auction ends in',
    auction_winner:'Winning bid',
    auction_holder:'Held by',
    auction_bid:'Place bid',
    auction_settle:'Settle',
    auction_bidlist:'View all bids',
    auction_bidview:'No bids yet',

    octopus_tittle:'Octopus guide Paul',
    octopus_content1:'Fufufu！You are about to enter the Neo world: the Polarized Sea!',
    octopus_content2:'Everything you once knew will be overturned in this crazy ocean. No one can help you to survive except yourself. You must to awaken, breaking your limitations, Crossing tribulation, dreaming together with companions, establish a new order, and survive in this sea. Only then can you discover your own treasure.',
    octopus_content3:'Once,TT who is a great prophet & captain bestowed upon your group of brave and crazy pioneers a name -- X-Order!',
    octopus_content4:'People gradually referred to the new order and government process established by the X-Orders on the Polarized Sea as the GrandLineDao.',
    octopus_content5:'If you are truly prepared, Let’s set sail and navigate the Polarized Sea.',
    octopus_content6:"Here's some friendly tips:",
    octopus_content7:'· Every 24 hours, a new X-Order becomes eligible to set sail, forever.',
    octopus_content8:'· Daily auctions are held for X-Order sailing permits, Participants must show their ‘determination’ to earn this.',
    octopus_content9:'· X-Order is made up of 11 different professional roles, including Captain, Swordsman, Navigator, Sniper, Chef, Doctor, Robot, Pet, Musician, Helmsman, and Mermaid. All characters are equally scarce.',
    octopus_content10:'· 100% of auction proceeds are deposited into a treasury named GrandLineDao.',
    octopus_content11:'· All X-Orders are part of GrandLineDao',
    octopus_content12:'· One X-Order , One vote.',
    octopus_content13:'· The use of funds in the GrandLineDao treasury is collectively governed  by the X-Orders.',
    octopus_content14:'· X-Orders can submit proposals in the Pirate Tavern(coming soon).',
    octopus_content15:'· Every 10 X-Order sailing permits will enter the GrandLineDao treasury, to be betted on the "Z-Generation.',

    library_tittle:'Library',
    library_content1:'1)  Who is  TT',
    library_content2:'One day, the sea of this world changed dramatically, and the "Polarized Sea" emerged, where the climate, terrain, and even the magnetic field could change dramatically at any moment, with it comes a serious of new order and opportunities.',
    library_content3:'TT is the origin of everything, the first prophet and captain who predicted the emergence of the Polarized Sea. He was also the first captain to navigate the journey after the Polarized Sea appeared.',
    library_content4:'As TT sailed, he shared everything he saw and heard with people on the continent who were eager to try.',
    library_content5:'One day, ',
    library_content6:"TT left a message: Awaken! Breaking limitation, Crossing tribulation, Dreaming together! Let's party on this polarized sea! , X-Orders!",
    library_content7:"and then he disappeared from people's sight forever. People speculated that he had either found a great treasure or immersed himself in his own adventure.",
    library_content8:'After that, more and more adventurers were inspired by his spirit and start their adventures. People called his sea route GrandLineDao.',
    library_content9:'This group of adventurers, who shared the same adventurous spirit, were collectively called X-Order.',
    library_content10:'2）What is GrandLineDao?',
    library_content11:'Initially, GrandLineDao referred to the route TT had once ventured on in the Polarized Sea.',
    library_content12:'As more adventurers (X-Orders) emerged, people gradually referred to the new order and government process established by the X-Orders on the Polarized Sea as ‘GrandLineDao’.',
    library_content13:'3）What is X-Order？',
    library_content14:'X-Order is a collective term for a group of brave and crazy pioneers on the GrandLineDao!',
    library_content15:'‘X’ stands for the unknown, and ‘Order’ stands for order.‘X-Order’ stands for exploration unknown order.',
    library_content16:'Many of the first X-Orders were crew members who had been on TT ships.',
    library_content17:'Based on the initial crew configuration, X-Order has 11 professions, including captain, swordsman, sailor girl, sniper, chef, doctor, robot, pet, musician, helmsman, and mermaid. Each profession has different traits, but all characters are equally scarce.',
    library_content18:"4）Auction and Setting of X-Order's Seafaring Certificates",
    library_content19:'Although everyone is free on the sea, obtaining the X-Order title is still the glory of all adventurers. GrandLineDao auctions an X-Order sailing permit on grandlinedao.xyz every 24h, forever. For every 10 X-Order auctions, GrandLineDao reserves one X-Order to give to the "Z Generation" who have proven their ambitions.',
    library_content20:'Each X-Order is an ERC-721 token, and all X-Order ERC-721 tokens can be viewed here: ',
    library_content20_oslink:'https://opensea.io/collection/grandlinedao',
    library_content21:'Because of the way Ethereum works,  each X-Order auctions must be settled after the previous auction is completed. The auction settlement transaction does three things: 1) it sends the current X-Order ERC-721 token to the winner of the auction, 2) generates the next X-Order, and 3) begins the next 24 hour X-Order auction.',
    library_content22:'5）GrandLineDao Treasury',
    library_content23:'The proceeds from the X-Order auctions will go 100% into the GrandLineDao Treasury, which is governed by X-Orders.',
    library_content24:'Each X-Order is an  irrevocable member of GrandLineDao, and entitled to one vote in all governance to build new orders on the "polarized sea".',
    library_content25:'Votes are non-transferable (if you sell your X-Order the vote goes with it) but delegatable, which means you can assign your vote to someone else as long as you own your X-Order.',
    library_content26:'6）Links',

  }
}
