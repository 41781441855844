import './index.css';
import 'wowjs/css/libs/animate.css';

import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import { Web3Provider } from './libs/web3Store/provider';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const queryClient = new QueryClient();
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorBgSpotlight: '#A6A8B9'
          }
        }}
      >
        <Web3Provider>
          <Router>
            <App />
          </Router>
        </Web3Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </ConfigProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
