/* eslint-disable no-case-declarations */
export function waitTime(timeMs: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('');
    }, timeMs);
  });
}

export function getParams(key: string) {
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const params = new window.URLSearchParams(window.location.search);
  return params.get(key) ?? '';
}

export interface ITree {
  key: string;
  children?: ITree[];
  parentKeys: string[];
}
export function flatTreeData(treeData: ITree[] | null, parentKey = '') {
  const result: ITree[] = [];
  if (!treeData) {
    return [];
  }

  treeData.forEach((item: ITree) => {
    if (item && item.children) {
      result.push(...flatTreeData(item.children, item.key));
    } else {
      item.parentKeys = [];
      item.parentKeys.push(parentKey);
      result.push({
        ...item
      });
    }
  });

  return result;
}

export function deduplicate<T extends Record<string, unknown>>(
  arr: T[],
  key = 'id'
) {
  const record: Record<string, boolean> = {};
  const newList: T[] = [];
  arr.forEach((item) => {
    const recordKey = item[key] as string;
    if (!record[recordKey]) {
      newList.push(item);
      record[recordKey] = true;
    }
  });

  return newList;
}

export const walletAddressEllipsis = (
  address: string | undefined,
  prefixLen = 4,
  suffixLen = 4
): string => {
  if (!address) {
    return '';
  }
  return address.slice(0, prefixLen) + '...' + address.slice(-suffixLen);
};

export function calcLeftTime(endDateTimeStampMs: number) {
  const leftTimeStamp = endDateTimeStampMs - Date.now();
  if (leftTimeStamp > 0) {
    // 代表时间没到，计算剩余天数、小时、分钟即可,1天86400000毫秒
    const days = Math.floor(leftTimeStamp / 86400000);
    const leftLessOneDayMs = leftTimeStamp - days * 86400000;
    // 1小时3600000毫秒
    const hours = Math.floor(leftLessOneDayMs / 3600000);
    // 1分钟 60000毫秒
    const minutes = Math.floor((leftLessOneDayMs - hours * 3600000) / 60000);
    // 1秒 10000毫秒
    const seconds = Math.floor(
      ((leftLessOneDayMs - hours * 3600000) % 60000) / 1000
    );
    return {
      days,
      hours,
      minutes,
      seconds,
      isOutOfDate: false
    };
  }
  return {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    isOutOfDate: true
  };
}

export function truncateNumber(number: number, decimalPlaces: number) {
  const factor = 10 ** decimalPlaces;
  const truncatedNumber = Math.trunc(number * factor) / factor;
  return truncatedNumber;
}

export function calculateAuctionTimes(
  auctionLastTime: number,
  auctionTokenId: number
) {
  const auctionTimes: number[] = [];

  for (let i = 0; i <= auctionTokenId; i++) {
    if (i === 0) {
      const previousTime = new Date('June 3, 2023').getTime();
      auctionTimes.unshift(previousTime);
    } else if (i % 10 === 0 || i === 1) {
      const previousTime = auctionTimes[i - 1];
      auctionTimes.push(previousTime);
    } else {
      const previousTime = auctionTimes[i - 1] + 24 * 60 * 60 * 1000;
      auctionTimes.push(previousTime);
    }
  }

  return auctionTimes;
}
