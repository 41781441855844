import { createContext } from 'react';

import { WalletStatus } from './ethereum/useWallet';

export interface WalletInfo {
  address: string;
  networkName: string;
  balance: string;
  chainId: string;
}

export interface SignData {
  nonce: string;
  signature: string;
  address: string;
}

interface Web3Context {
  connect: () => Promise<void>;
  unConnect: () => void;
  walletInfo: WalletInfo;
  walletStatus: WalletStatus;
  sign: () => Promise<SignData>;
  getClient: () => null | undefined | void;
  getUserBalance: (address: string) => Promise<{ balance: string }>;
}

export const Web3Context = createContext({
  walletInfo: {
    address: '',
    networkName: '',
    balance: '',
    chainId: ''
  },
  walletStatus: WalletStatus.UNCONNECTED,
  getClient() {
    //
  }
} as Web3Context);
