import { notification } from 'antd';
import React, { useEffect } from 'react';

import { Web3Context } from './context';
import { useEthereumWallet, WalletStatus } from './ethereum/useWallet';

interface Web3ProviderProps {
  children: JSX.Element;
}

export const Web3Provider = (props: Web3ProviderProps) => {
  const ethereumValue = useEthereumWallet();

  useEffect(() => {
    notification.destroy('networkWrongTips');
    const chainId = process.env.REACT_APP_ETH_CHAIN_ID;
    const networkName = process.env.REACT_APP_ETH_CHAIN_NAME || 'homestead';

    if (
      ethereumValue?.walletStatus === WalletStatus.CONNECTED &&
      ethereumValue?.walletInfo?.chainId !== chainId
    ) {
      notification.warning({
        className: 'top-[64px]',
        placement: 'topRight',
        duration: null,
        closeIcon: null,
        key: 'networkWrongTips',
        message: `please switch to ${networkName}`
      });
    }
  }, [ethereumValue?.walletInfo?.chainId, ethereumValue?.walletStatus]);

  return (
    <Web3Context.Provider value={ethereumValue}>
      {props.children}
    </Web3Context.Provider>
  );
};
