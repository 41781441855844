import { useEffect, useState } from 'react';

import { calcLeftTime } from '../common';

/**
 * 倒计时Hook，判断是否到时间，返回剩余天数、小时、分钟、秒
 * @param endTimeStamp 通过 https://tool.lu/timestamp/ ,将北京时间xxxx-xx-xx xx:xx:xx转换为时间戳，单位为ms
 * @param step 倒计时的间隔，默认1000ms
 * @returns
 */
export function useTimeCountDown(endDateTimeStampMs: number, step = 1000) {
  const [leftDays, setLeftDays] = useState<number>(0);
  const [leftHours, setLeftHours] = useState<number>(0);
  const [leftMinutes, setLeftMinutes] = useState<number>(0);
  const [leftSeconds, setLeftSeconds] = useState<number>(0);
  const [isOutOfDate, setIsOutOfDate] = useState<boolean>(
    calcLeftTime(endDateTimeStampMs).isOutOfDate
  );

  // count down
  useEffect(() => {
    const { days, hours, minutes, seconds, isOutOfDate } =
      calcLeftTime(endDateTimeStampMs);
    setLeftDays(days);
    setLeftHours(hours);
    setLeftMinutes(minutes);
    setLeftSeconds(seconds);
    setIsOutOfDate(isOutOfDate);
    if (isOutOfDate) return;
    const intervalId = setInterval(() => {
      const result = calcLeftTime(endDateTimeStampMs);
      setLeftDays(result.days);
      setLeftHours(result.hours);
      setLeftMinutes(result.minutes);
      setLeftSeconds(result.seconds);
      setIsOutOfDate(result.isOutOfDate);
      if (result.isOutOfDate) clearInterval(intervalId);
    }, step);
    return () => {
      clearInterval(intervalId);
    };
  }, [endDateTimeStampMs, step]);

  return { leftDays, leftHours, leftMinutes, leftSeconds, isOutOfDate };
}
