import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMount } from 'react-use';

export function useAnchor() {
  const location = useLocation();
  const scrollToAnchor = (anchorName: string) => {
    if (anchorName) {
      // 找到锚点
      const anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        try {
          anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
        } catch (e) {
          // 低版本chrome(<61)
          anchorElement.scrollIntoView();
        }
      }
    }
  };

  useMount(() => {
    setTimeout(() => {
      if (location.hash) {
        const anchor = location.hash.replace('#', '');
        scrollToAnchor(anchor);
      }
    }, 800);
  });

  useEffect(() => {
    if (location.hash) {
      const anchor = location.hash.replace('#', '');
      scrollToAnchor(anchor);
    }
  }, [location.hash]);
}
