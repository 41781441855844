import { ContractFactory } from '@white-matrix/grandlinedao-sdk';
import { ethers } from 'ethers';

class ProductService {
  network: number;
  contractFactory: ContractFactory;

  constructor() {
    this.network = Number(process.env.REACT_APP_ETH_CHAIN_ID) || 11155111;
    this.contractFactory = new ContractFactory(this.network);
  }

  setNetwork(network: number) {
    this.network = network;
  }

  setContractFactory(network: number) {
    this.setNetwork(network);
    this.contractFactory = new ContractFactory(network);
  }

  getSigner() {
    if (!window.ethereum) {
      return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider?.getSigner();
    return signer;
  }

  getProductPool() {
    try {
      const productPool = this.contractFactory.getToken();

      return productPool;
    } catch (e) {
      console.log(e);
    }
  }

  getAuction() {
    const signer = this.getSigner();
    try {
      const auction = this.contractFactory.getAuction(signer);

      return auction;
    } catch (e) {
      console.log(e);
    }
  }

  getProvider() {
    try {
      const provider = this.contractFactory.getReadProvider();
      return provider;
    } catch (e) {
      console.log(e);
    }
  }
}

export const productService = new ProductService();
