import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import { ethers } from 'ethers';
import { useCallback, useRef, useState } from 'react';
import { useMount } from 'react-use';
import store from 'store2';
import { v4 as uuidV4 } from 'uuid';
import Web3Modal from 'web3modal';

import { SignData, WalletInfo } from '../context';

const defaultWalletInfo = {
  address: '',
  networkName: '',
  balance: '',
  chainId: ''
};

interface WalletConnectProvider {
  enable: () => Promise<void>;
  on: (eventType: string, callback: () => void) => void;
}

export enum WalletStatus {
  PENDING = 'pending',
  CONNECTED = 'connected',
  UNCONNECTED = 'unconnected'
}

export const useEthereumWallet = () => {
  const web3ModalRef = useRef<Web3Modal | null>(null);

  const [walletInfo, setWalletInfo] = useState(defaultWalletInfo);
  const [walletStatus, setWalletStatus] = useState<WalletStatus>(
    WalletStatus.UNCONNECTED
  );
  const provider = useRef<Web3Provider | null>(null);

  const getWalletInfo = useCallback(async () => {
    if (provider.current) {
      const network = await provider.current.getNetwork();
      const address = await provider.current.getSigner().getAddress();
      const balance = await provider.current.getBalance(address);

      return {
        address,
        networkName: network.name,
        balance: balance.toString(),
        chainId: network.chainId.toString()
      };
    }

    return defaultWalletInfo;
  }, []);

  const unConnect = useCallback(() => {
    if (web3ModalRef.current) {
      web3ModalRef.current.clearCachedProvider();
    }

    if (provider.current) {
      provider.current.removeAllListeners();

      const windowProvider = provider.current
        .provider as unknown as Web3Provider;
      windowProvider.removeAllListeners();
    }

    provider.current = null;
    web3ModalRef.current = null;

    store.remove('walletInfo');
    store.remove('authInfo');

    setWalletInfo(defaultWalletInfo);
    setWalletStatus(WalletStatus.UNCONNECTED);
  }, []);

  const connect = useCallback(async () => {
    setWalletStatus(WalletStatus.PENDING);

    async function reConnect() {
      unConnect();
      await connect();
    }

    try {
      web3ModalRef.current = new Web3Modal({
        network: 'mainnet',
        cacheProvider: true,
        providerOptions: {}
      });
      const web3 = web3ModalRef.current;

      const ethProvider = (await web3.connect()) as WalletConnectProvider;
      if (!ethProvider)
        throw new Error(
          'No Metamask detected, please install Metamask extension (https://metamask.io/)!'
        );
      await ethProvider.enable();

      provider.current = new ethers.providers.Web3Provider(
        ethProvider as ExternalProvider
      );

      ethProvider.on('chainChanged', () => {
        void reConnect();
      });
      ethProvider.on('accountsChanged', () => {
        void reConnect();
      });

      const walletInfo = await getWalletInfo();
      setWalletInfo(walletInfo);
      store.set('walletInfo', walletInfo);
      setWalletStatus(WalletStatus.CONNECTED);
    } catch (e) {
      setWalletStatus(WalletStatus.UNCONNECTED);
    }
  }, [getWalletInfo, unConnect]);

  const sign = useCallback(async (): Promise<SignData> => {
    if (!walletInfo.address) {
      await connect();
    }

    if (!provider.current) throw new Error('Metamask is not connected!');

    const nonce = `
    Welcome to test club!

    Click to sign in and accept the test Terms of Service: https://test.io

    This request will not trigger a blockchain transaction or cost any gas fees.

    Your authentication status will reset after 2 hours.

    Wallet address:
    ${walletInfo.address}

    Nonce:
    ${uuidV4()}
    `;

    const signer = provider.current.getSigner();
    const signature = await signer.signMessage(nonce);
    const address = await signer.getAddress();

    return {
      nonce,
      signature,
      address
    };
  }, [connect, walletInfo.address]);

  const getClient = () => {
    //
  };

  const getUserBalance = useCallback(async (address: string) => {
    if (provider.current) {
      const balance = await provider.current.getBalance(address);
      return { balance: balance.toString() };
    }
    return { balance: '' };
  }, []);

  useMount(() => {
    const walletInfo = store.get('walletInfo') as WalletInfo;
    if (walletInfo?.address) {
      void connect();
    }
  });

  return {
    connect,
    unConnect,
    sign,
    walletInfo,
    walletStatus,
    getClient,
    getUserBalance
  };
};
