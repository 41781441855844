import { useEffect } from 'react';

import { wowInstance } from '@/App';

/**
 * 开启sync，否则wowjs在第一次加载的时候动画不会正确显示
 */
export const useWowAnimateSync = () =>
  useEffect(() => {
    wowInstance.sync();
  }, []);
