import { Modal, Tree } from 'antd';
import { DataNode } from 'antd/es/tree';
import cn from 'classnames';
import React from 'react';

import octopus_checked from '@/assets/images/octopus_checked@2x.png';
import { useTranslation } from '@/i18n';
import { useDeviceSize } from '@/utils/hooks/useDeviceSize';

import styles from './OctopusModal.module.less';

interface OctopusModalProps {
  className?: string;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
}

export function OctopusModal(props: OctopusModalProps) {
  const { className, isOpen, setIsOpen } = props;
  const { t } = useTranslation();
  const { isSmallDevice } = useDeviceSize(867);

  const treeData: DataNode[] = [
    {
      title: (
        <div className="text-[20px] font-[1000]">{t('octopus_content6')}</div>
      ),
      key: '0-0',
      children: [
        {
          title: (
            <div className="mt-1 rounded-[20px] bg-[#FFFFFF66] px-5 py-4 pr-2 text-[16px] leading-[180%]">
              <div className="py-2">{t('octopus_content7')}</div>
              <div className="py-2">{t('octopus_content8')}</div>
              <div className="py-2">{t('octopus_content9')}</div>
              <div className="py-2">{t('octopus_content10')}</div>
              <div className="py-2">{t('octopus_content11')}</div>
              <div className="py-2">{t('octopus_content12')}</div>
              <div className="py-2">{t('octopus_content13')}</div>
              <div className="py-2">{t('octopus_content14')}</div>
            </div>
          ),
          key: '0-0-0'
        }
      ]
    }
  ];

  return (
    <Modal
      className={cn(className, styles.modal1)}
      centered={true}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
    >
      <div className={cn('relative h-full w-full font-Zpix')}>
        <img
          className={cn(
            'absolute left-[-15%]   ',
            isSmallDevice ? 'top-[-50px] h-[100px]' : 'top-[-80px] h-[160px]'
          )}
          src={octopus_checked}
        />
        <div>
          <div
            className={cn(
              ' text-[#ffffff]',
              isSmallDevice
                ? 'ml-2 pt-[30px] text-[20px]'
                : 'ml-[100px] text-[32px] '
            )}
          >
            {t('octopus_tittle')}
          </div>
          <div
            className={cn(
              'text-[#000000EO] overflow-y-auto  font-Zpix text-[16px] font-[600]',
              styles.textArea,
              isSmallDevice
                ? 'mt-4 max-h-[300px] px-2'
                : 'mt-[45px] max-h-[500px] px-10 '
            )}
          >
            <div>{t('octopus_content1')}</div>
            <div className="mt-[30px]">{t('octopus_content2')}</div>
            <div className="mt-[30px]">{t('octopus_content3')}</div>
            <div className="mt-[30px]">{t('octopus_content4')}</div>
            <div className="mt-[30px]">{t('octopus_content5')}</div>

            <div className="mt-[30px] ml-[-10px]">
              <Tree
                className={cn('font-Zpix')}
                showIcon={true}
                defaultExpandAll={true}
                defaultSelectedKeys={['0-0-0']}
                treeData={treeData}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
