import { Layout } from 'antd';
import cn from 'classnames';
import React, { ReactNode } from 'react';

// import { Footer } from '../Footer';
import { Header } from '../Header';
import { MusicArea } from './MusicArea';

const { Content } = Layout;

interface AppLayoutProps {
  children: ReactNode;
  className?: string;
}

export function AppLayout(props: AppLayoutProps) {
  const { className, children } = props;
  // const audioRef = useRef<HTMLAudioElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [audio, state, controls, ref] = useAudio({
  //   src: homepageBgm,
  //   autoPlay: true,
  //   loop: true,
  //   preload: 'auto'
  // });

  // useEffect(() => {
  //   const audioElement = ref.current;
  //   if (!audioElement) return;
  //   const handleScroll = () => {
  //     // const scrollPosition = window.scrollY || window.pageYOffset;
  //     // // 根据滚动位置或其他条件判断是否播放音乐
  //     // if (scrollPosition > 0) {
  //     //   void controls.play();
  //     // } else {
  //     //   controls.pause();
  //     // }
  //     void controls.play();
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [controls, ref]);

  return (
    <div className="relative min-h-[100vh] w-[100%]">
      <Layout
        className={cn(
          className,
          'absolute top-0 left-0 h-[100%] w-[100%] overflow-x-hidden bg-[#F8FAFC]'
        )}
      >
        <Header />
        <Content className="">{children}</Content>
        {/* <Footer /> */}
        <MusicArea />
      </Layout>
    </div>
  );
}
