/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { AuctionBidEvent } from '@white-matrix/grandlinedao-sdk/dist/contracts/contracts/auction/Auction';
import { Modal, Tooltip } from 'antd';
import cn from 'classnames';
import React from 'react';

import allBidsLogo from '@/assets/images/allBidsLogo@2x.png';
import vector from '@/assets/images/vector.png';
import { truncateNumber, walletAddressEllipsis } from '@/utils/common';
import { useDeviceSize } from '@/utils/hooks/useDeviceSize';

import { AllBidDetails } from '../AllBidDetails';
import styles from './ViewAllBidsModal.module.less';

interface ViewAllBidsModalProps {
  className?: string;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  bidsList: AuctionBidEvent[];
  daoImage: string;
}

export function ViewAllBidsModal(props: ViewAllBidsModalProps) {
  const { className, isOpen, setIsOpen, bidsList, daoImage } = props;
  const { width, isSmallDevice } = useDeviceSize();

  return (
    <Modal
      className={cn(className, styles.modal1)}
      centered={true}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
    >
      <div
        className={cn(
          'h-full w-full  font-Zpix',
          isSmallDevice ? '' : 'p-[50px]'
        )}
      >
        <div className={cn('flex items-center')}>
          <img
            src={daoImage}
            className="w-[82px] rounded-[10px]"
            draggable={false}
          />
          <div className="ml-6 text-[#ffffff]">
            <div className={cn(isSmallDevice ? 'text-[14px]' : 'text-[20px]')}>
              Bids for
            </div>
            <div className={cn(isSmallDevice ? 'text-[22px]' : 'text-[32px]')}>
              X-Order {bidsList[0].args.tokenId.toString()}
            </div>
          </div>
        </div>

        <div className="mt-[30px] h-full w-full rounded-[20px] bg-[#FFFFFF66] py-[30px] px-5">
          <div className="max-h-[364px] w-full overflow-y-auto overflow-x-hidden  pr-5">
            {bidsList.map((item, index) => (
              <div
                key={index}
                className={cn(
                  ' mb-4 flex min-h-[98px]  rounded-[10px] bg-[#FFFFFF] px-5 py-4',
                  isSmallDevice
                    ? 'flex-col justify-center'
                    : 'flex-row items-center justify-between'
                )}
              >
                <div className="flex items-center">
                  <div
                    className={cn(
                      ' rounded-[100%]',
                      isSmallDevice ? 'h-[30px] w-[30px]' : 'h-[66px] w-[66px]'
                    )}
                    style={{
                      background:
                        'linear-gradient(180deg, #3AADCC 0%, #D3F4FF 100%)'
                    }}
                  />

                  <div className="ml-5">
                    <div
                      className={cn(
                        isSmallDevice ? 'text-[18px]' : 'text-[24px]'
                      )}
                    >
                      {walletAddressEllipsis(item.args.bidder)}
                    </div>
                    <div className={cn(isSmallDevice ? 'text-3' : 'text-4')}>
                      {/* {new Date(
                        Number(item.args.endTime) * 1000
                      ).toLocaleString('en-US', {
                        timeZone: 'Asia/Shanghai',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })} */}
                      <AllBidDetails blockNumber={item.blockNumber} />
                    </div>
                  </div>
                </div>

                <div
                  className={cn(
                    'flex items-center',
                    isSmallDevice ? ' mt-[10px] justify-between' : ''
                  )}
                >
                  <Tooltip
                    title={`${Number(item.args.amount.toString()) / 1e18} ETH`}
                  >
                    <div
                      className={cn(
                        isSmallDevice ? 'mr-2 text-[18px]' : 'mr-5 text-[28px]'
                      )}
                    >
                      {truncateNumber(
                        Number(item.args.amount.toString()) / 1e18,
                        2
                      )}{' '}
                      ETH
                    </div>
                  </Tooltip>
                  <img
                    className="cursor-pointer"
                    draggable={false}
                    src={vector}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_ETHER_SCAN_URL}/tx/${item.transactionHash}`
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
