import { Button, Dropdown, MenuProps, Tooltip } from 'antd';
import cn from 'classnames';
import { useContext, useState } from 'react';

import userLogo from '@/assets/images/blue_panda@2x.png';
import logo from '@/assets/images/header-logo@2x.png';
import menuIcon from '@/assets/images/menu@2x.png';
import { switchLanguage, useTranslation } from '@/i18n';
import { Web3Context } from '@/libs/web3Store/context';
import { truncateNumber, walletAddressEllipsis } from '@/utils/common';
import { useDeviceSize } from '@/utils/hooks/useDeviceSize';
import { useTreasury } from '@/utils/hooks/useTreasury';

import styles from './Header.module.less';

interface HeaderProps {
  className?: string;
}

export function Header(props: HeaderProps) {
  const { className } = props;
  const { t } = useTranslation();
  const { isSmallDevice, width } = useDeviceSize(867);

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isOpenDisconnectButton, setIsOpenDisconnectButton] =
    useState<boolean>(false);

  const { walletInfo, unConnect, connect } = useContext(Web3Context);
  const {
    treasury: { treasuryBalance }
  } = useTreasury();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="text-center font-Zpix text-[20px] font-[400]"
          onClick={() => void unConnect()}
        >
          Disconnect
        </div>
      )
    }
  ];

  const languageItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="w-[120px] text-center font-Zpix text-[16px] font-[400] xl:w-[160px] xl:text-[20px]"
          onClick={() => switchLanguage('en-US')}
        >
          English
        </div>
      )
    },
    {
      key: '2',
      label: (
        <div
          className="w-[120px] text-center font-Zpix text-[16px] font-[400] xl:w-[160px] xl:text-[20px]"
          onClick={() => switchLanguage('zh')}
        >
          简体中文
        </div>
      )
    }
  ];

  return (
    <div
      className={cn(
        className,
        styles.header,
        'main-container relative z-20 flex min-h-[71px] w-screen items-center  bg-[#50BBD7] py-[6px] font-Zpix text-[#ffffff]'
      )}
      style={{ boxShadow: '0px 1px 6px #50BBD7' }}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <img src={logo} className="h-[40px] " draggable={false} />

          {width >= 400 && (
            <Tooltip title={`${Number(treasuryBalance) / 1e18} ETH`}>
              <div
                className={cn(
                  'ml-[25px] whitespace-nowrap text-[16px]  xl:text-[21px]',
                  isSmallDevice ? 'font-bold text-[#1B62FA]' : ''
                )}
              >
                Treasury: {truncateNumber(Number(treasuryBalance) / 1e18, 2)}{' '}
                ETH
              </div>
            </Tooltip>
          )}
          {width < 400 && (
            <div className="ml-2 rounded-[2px] bg-[#86D3E8] px-3 py-2 font-Zpix font-bold text-[#000000]">
              ≡ {truncateNumber(Number(treasuryBalance) / 1e18, 2)}
            </div>
          )}
        </div>

        {!isSmallDevice && (
          <div className="flex items-center text-[16px] xl:text-[21px]">
            <div className="ml-5 cursor-pointer xl:ml-10">{t('nav_dao')}</div>
            <div className="ml-5 cursor-pointer xl:ml-10">{t('nav_doc')}</div>
            <div className="ml-5 cursor-pointer xl:ml-10 ">
              {t('nav_discourse')}
            </div>
            <Dropdown
              overlayClassName={styles.dropdown}
              menu={{ items: languageItems }}
            >
              <div className="flex h-[42px] items-center">
                <div className="ml-5 cursor-pointer xl:ml-10">
                  {t('language')}
                </div>
                <div
                  style={{
                    width: '0',
                    height: '0',
                    borderLeft: '6px solid transparent',
                    borderRight: '6px solid transparent',
                    borderTop: '6px solid #ffffff',
                    fontSize: '0',
                    lineHeight: '0',
                    margin: '4px 0 0 8px'
                  }}
                />
              </div>
            </Dropdown>

            {!walletInfo.address && (
              <Button
                className="ml-10 h-[42px] cursor-pointer rounded-[4px] bg-[#000000] px-7 font-Zpix font-semibold text-[#ffffff]"
                onClick={() => void connect()}
              >
                <div>{t('nav_wallet')}</div>
              </Button>
            )}

            {walletInfo.address && (
              <Dropdown menu={{ items }}>
                <div className="mx-4 flex cursor-pointer items-center rounded-[10px] bg-[#1B62FA] px-3 py-2 font-Zpix">
                  <img
                    src={userLogo}
                    className="mr-2 h-[26px] rounded-[50%]"
                    draggable={false}
                  />

                  <div>{walletAddressEllipsis(walletInfo.address)}</div>

                  <div
                    style={{
                      width: '0',
                      height: '0',
                      borderLeft: '6px solid transparent',
                      borderRight: '6px solid transparent',
                      borderTop: '6px solid #ffffff',
                      fontSize: '0',
                      lineHeight: '0',
                      margin: '4px 0 0 8px'
                    }}
                  />
                </div>
              </Dropdown>
            )}
          </div>
        )}

        {isSmallDevice && (
          <img
            src={menuIcon}
            className="h-[30px] w-[30px] cursor-pointer "
            onClick={() => setIsOpenMenu(!isOpenMenu)}
          />
        )}
      </div>

      {isSmallDevice && isOpenMenu && (
        <div className="main-container absolute top-[71px] left-0 min-h-[300px] w-full border-t-[2px] border-[#ffffff] bg-[#50BBD7] py-6 font-Zpix">
          <div className="mb-2 w-full rounded-[4px] bg-[#86D3E8] py-3 text-center">
            {t('nav_dao')}
          </div>
          <div className="mb-2 w-full rounded-[4px] bg-[#86D3E8] py-3 text-center">
            {t('nav_doc')}
          </div>
          <div className="mb-2 w-full rounded-[4px] bg-[#86D3E8] py-3 text-center">
            {t('nav_discourse')}
          </div>
          <Button
            className="mb-2 h-10 w-full cursor-pointer rounded-[4px] bg-[#86D3E8] text-center font-Zpix text-[#ffffff]"
            onClick={() =>
              switchLanguage(t('language') === 'English' ? 'zh' : 'en-US')
            }
          >
            {t('language') === 'English' ? '简体中文' : 'English'}
          </Button>

          {!walletInfo.address && (
            <Button
              className="mb-2 h-[43px] w-full cursor-pointer rounded-[4px] bg-[#86D3E8] text-center font-Zpix"
              onClick={() => void connect()}
            >
              {t('nav_wallet')}
            </Button>
          )}

          {walletInfo.address && (
            <div
              className="flex cursor-pointer items-center justify-center rounded-[4px] bg-[#1B62FA] px-3 py-3 font-Zpix"
              onClick={() => setIsOpenDisconnectButton(!isOpenDisconnectButton)}
            >
              <img
                src={userLogo}
                className="mr-2 h-[26px] rounded-[50%]"
                draggable={false}
              />

              <div>{walletAddressEllipsis(walletInfo.address)}</div>

              <div
                style={{
                  width: '0',
                  height: '0',
                  borderLeft: '6px solid transparent',
                  borderRight: '6px solid transparent',
                  borderTop: '6px solid #ffffff',
                  fontSize: '0',
                  lineHeight: '0',
                  margin: '4px 0 0 8px'
                }}
              />
            </div>
          )}

          {walletInfo.address && isOpenDisconnectButton && (
            <Button
              className="mt-2 h-10 w-full cursor-pointer rounded-[4px] bg-[#86D3E8] text-center font-Zpix text-[#ffffff]"
              onClick={() => unConnect()}
            >
              Disconnect
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
