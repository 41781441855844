import { Tree } from 'antd';
import { DataNode } from 'antd/es/tree';
import cn from 'classnames';
import React from 'react';

import cloudLeft_bg from '@/assets/images/clouds/cloudLeft_bg@2x.png';
import cloudRight_bg from '@/assets/images/clouds/cloudRight_bg@2x.png';
import octopus from '@/assets/images/octopus_island@2x.png';
import { useTranslation } from '@/i18n';

import styles from './TextArea.module.less';

interface TextAreaProps {
  className?: string;
}

export function TextArea(props: TextAreaProps) {
  const { className } = props;
  const { t } = useTranslation();

  const treeData: DataNode[] = [
    {
      title: (
        <div className="font-Furore text-[16px] leading-[180%]">
          {t('library_content1')}
        </div>
      ),
      key: '0-0',

      children: [
        {
          title: (
            <div
              className={cn(
                'rounded-[20px] bg-[#FFFFFF66] py-4 px-2  pr-2 text-[16px] leading-[180%]'
              )}
            >
              <div className="h-full max-h-[250px] overflow-y-auto pr-2">
                <div>{t('library_content2')}</div>
                <div>{t('library_content3')}</div>
                <div>{t('library_content4')}</div>
                <div>{t('library_content5')}</div>
                <div>{t('library_content6')}</div>
                <div>{t('library_content7')}</div>
                <div>{t('library_content8')}</div>
                <div>{t('library_content9')}</div>
              </div>
            </div>
          ),
          key: '0-0-0'
        }
      ]
    },
    {
      title: (
        <div className=" font-Furore text-[16px] leading-[180%]">
          {t('library_content10')}
        </div>
      ),
      key: '0-1',

      children: [
        {
          title: (
            <div
              className={cn(
                ' rounded-[20px] bg-[#FFFFFF66] py-4 px-2  pr-2 text-[16px] leading-[180%]'
              )}
            >
              <div className="h-full max-h-[250px] overflow-y-auto pr-2">
                <div>{t('library_content11')}</div>
                <div>{t('library_content12')}</div>
              </div>
            </div>
          ),
          key: '0-1-0'
        }
      ]
    },
    {
      title: (
        <div className=" font-Furore text-[16px] leading-[180%]">
          {t('library_content13')}
        </div>
      ),
      key: '0-2',

      children: [
        {
          title: (
            <div
              className={cn(
                'rounded-[20px] bg-[#FFFFFF66] py-4 px-2  pr-2 text-[16px] leading-[180%]'
              )}
            >
              <div className="h-full max-h-[250px] overflow-y-auto pr-2">
                <div>{t('library_content14')}</div>
                <div>{t('library_content15')}</div>
                <div>{t('library_content16')}</div>
                <div>{t('library_content17')}</div>
              </div>
            </div>
          ),
          key: '0-2-0'
        }
      ]
    },
    {
      title: (
        <div className=" font-Furore text-[16px] leading-[180%]">
          {t('library_content18')}
        </div>
      ),
      key: '0-3',

      children: [
        {
          title: (
            <div
              className={cn(
                'rounded-[20px] bg-[#FFFFFF66]  py-4  px-2 pr-2 text-[16px] leading-[180%]'
              )}
            >
              <div className="h-full max-h-[250px] w-full overflow-y-auto pr-2">
                <div>{t('library_content19')}</div>
                <div>
                  {t('library_content20')}{' '}
                  <a
                    className="text-[blue]"
                    href={t('library_content20_oslink')}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => window.open(t('library_content20_oslink'))}
                    style={{ wordBreak: 'break-all' }}
                  >
                    {t('library_content20_oslink')}
                  </a>
                </div>

                <div>{t('library_content21')}</div>
              </div>
            </div>
          ),
          key: '0-3-0'
        }
      ]
    },
    {
      title: (
        <div className=" font-Furore text-[16px] leading-[180%]">
          {t('library_content22')}
        </div>
      ),
      key: '0-4',

      children: [
        {
          title: (
            <div
              className={cn(
                'rounded-[20px] bg-[#FFFFFF66] py-4 px-2  pr-2 text-[16px] leading-[180%] '
              )}
            >
              <div className="h-full max-h-[250px] overflow-y-auto pr-2">
                <div>{t('library_content23')}</div>
                <div>{t('library_content24')}</div>
                <div>{t('library_content25')}</div>
              </div>
            </div>
          ),
          key: '0-4-0'
        }
      ]
    },
    {
      title: (
        <div className=" font-Furore text-[16px] leading-[180%]">
          {t('library_content26')}
        </div>
      ),
      key: '0-5',

      children: [
        {
          title: (
            <div className="max-h-[250px] overflow-y-auto rounded-[20px] bg-transparent px-5 py-4  text-[16px] leading-[180%]"></div>
          ),
          key: '0-5-0'
        }
      ]
    }
  ];

  return (
    <div className={cn(styles.TextArea, className)}>
      <div className="relative z-[1] min-h-[500px] w-full bg-[#71CBE4]">
        <img
          className="absolute left-0 top-[97px] z-[-1] w-[67px]"
          src={cloudLeft_bg}
        />
        <img
          className="absolute right-0 top-[50%] z-[-1] w-[67px] translate-y-[-50%]"
          src={cloudRight_bg}
        />

        <div className=" px-4 py-5">
          <img className="w-[125px]" src={octopus} />
          <div className="pl-[10px] font-Zpix text-[16px] font-[600] leading-[21px]">
            <div>{t('octopus_content1')}</div>
            <div className="py-1">{t('octopus_content2')}</div>
            <div className="py-1">{t('octopus_content3')}</div>
            <div className="py-1">{t('octopus_content4')}</div>
            <div className="py-1">{t('octopus_content5')}</div>
          </div>

          <Tree
            className={cn(
              'mt-5 select-text bg-transparent font-Zpix font-[600]'
            )}
            showIcon={true}
            defaultExpandAll={false}
            defaultSelectedKeys={['0-0-0']}
            // switcherIcon={<DownOutlined />}
            treeData={treeData}
          />
        </div>
      </div>
    </div>
  );
}
