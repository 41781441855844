/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import cloud1 from '@/assets/images/clouds/cloud1@2x.png';
import cloud2 from '@/assets/images/clouds/cloud2@2x.png';
import cloud3_1 from '@/assets/images/clouds/cloud3_1@2x.png';
import cloud3_2 from '@/assets/images/clouds/cloud3_2@2x.png';
import cloud4 from '@/assets/images/clouds/cloud4@2x.png';
import cloud5 from '@/assets/images/clouds/cloud5@2x.png';
import darkCloud from '@/assets/images/clouds/dark_clouds@2x.png';
import land1 from '@/assets/images/islands/islands1@2x.png';
import land1_checked from '@/assets/images/islands/islands1-checked@2x.png';
import land2 from '@/assets/images/islands/islands2@2x.png';
import land3 from '@/assets/images/islands/islands3@2x.png';
import map from '@/assets/images/map.png';
import octopus_checked from '@/assets/images/octopus_checked@2x.png';
import octopus from '@/assets/images/octopus_island@2x.png';
import { useDeviceSize } from '@/utils/hooks/useDeviceSize';

import styles from './Map.module.less';

interface MapProps {
  className?: string;
  setModal1Open: React.Dispatch<React.SetStateAction<boolean>>;
  setModal2Open: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MapLands(props: MapProps) {
  const { className, setModal1Open, setModal2Open } = props;
  const [hovered, setHovered] = useState<string>('');

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { isSmallDevice } = useDeviceSize(867);

  useEffect(() => {
    const handleMouseMove = (event: { clientX: any; clientY: any }) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div
      className={cn(
        className,
        'relative w-screen select-none overflow-x-hidden'
      )}
    >
      <img src={map} className="w-full" draggable={false} />

      <div className={cn(styles.downArrow)} />

      {/* <div className={cn(styles.cloud1)} /> */}
      {/* <div className={cn(styles.cloud2)} /> */}
      {/* <div className={cn(styles.cloud3_1)} /> */}
      {/* <div className={cn(styles.cloud3_2)} /> */}
      {/* <div className={cn(styles.cloud4)} /> */}
      {/* <div className={cn(styles.cloud5)} /> */}
      {/*  <div className={cn(styles.darkCloud)} /> */}

      <img
        src={cloud1}
        style={{
          position: 'absolute',
          top: '28%',
          left: '12%',
          width: '25%',
          transform: `translate(${position.x * 0.008}px, ${
            position.y * 0.008
          }px)`
        }}
        draggable={false}
      />

      <img
        src={cloud2}
        style={{
          position: 'absolute',
          top: '32%',
          right: '-2%',
          width: '20%',
          transform: `translate(${-position.x * 0.008}px, ${
            -position.y * 0.008
          }px)`
        }}
        draggable={false}
      />

      <img
        src={darkCloud}
        style={{
          position: 'absolute',
          left: '24%',
          top: '30%',
          width: '50%',

          transform: `translate(${position.x * 0.008}px, ${
            position.y * 0.008
          }px)`
        }}
        draggable={false}
      />

      <img
        src={cloud3_1}
        style={{
          position: 'absolute',
          left: '12%',
          top: '42%',
          width: '30%',
          transform: `translate(${-position.x * 0.008}px, ${
            -position.y * 0.008
          }px)`
        }}
        draggable={false}
      />

      <img
        src={cloud3_2}
        style={{
          position: 'absolute',
          left: '64%',
          top: '44%',
          width: '10%',
          transform: `translate(${position.x * 0.008}px, ${
            position.y * 0.008
          }px)`
        }}
        draggable={false}
      />

      <img
        src={cloud4}
        style={{
          position: 'absolute',
          left: 0,
          top: '56%',
          width: '30%',
          transform: `translate(${-position.x * 0.008}px, ${
            -position.y * 0.002
          }px)`
        }}
        draggable={false}
      />

      <img
        src={cloud5}
        style={{
          position: 'absolute',
          right: 0,
          top: '18%',
          width: '30%',
          transform: `translate(${-position.x * 0.008}px, ${
            -position.y * 0.008
          }px)`
        }}
        draggable={false}
      />

      <div
        className={cn(
          'absolute left-[23.5%] top-[12.5%] h-[8%] w-[18%]',
          !isSmallDevice && 'cursor-pointer'
        )}
        onMouseEnter={() => setHovered('octopus')}
        onMouseLeave={() => setHovered('')}
        onClick={() => !isSmallDevice && setModal1Open(true)}
      >
        {hovered === 'octopus' && !isSmallDevice ? (
          <img src={octopus_checked} draggable={false} />
        ) : (
          <img src={octopus} draggable={false} />
        )}
      </div>

      <div
        className={cn(
          'absolute top-[21.5%] left-[33%] w-[50%] ',
          !isSmallDevice && 'cursor-pointer'
        )}
        onMouseEnter={() => setHovered('secondLand')}
        onMouseLeave={() => setHovered('')}
        onClick={() => !isSmallDevice && setModal2Open(true)}
      >
        {hovered === 'secondLand' && !isSmallDevice ? (
          <img src={land1_checked} draggable={false} />
        ) : (
          <img src={land1} draggable={false} />
        )}
      </div>

      <img
        src={land2}
        className="absolute bottom-[42.5%] left-[22%] w-[60%] "
        draggable={false}
      />
      <img
        src={land3}
        className="absolute bottom-[13.5%] left-0 w-[80%] "
        draggable={false}
      />

      <div className="main-container absolute bottom-0 left-0 z-10 flex h-[60px] w-full items-center justify-between  bg-[#87ABC785] font-Zpix text-[16px] md:h-[82px] md:justify-center md:text-[21px] ">
        <div className=" mx-1 md:mx-[90px]">
          <a
            href="https://twitter.com/grandlinedao"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>
        </div>
        <div className="mx-1 md:mx-[90px]">
          <a
            href="https://etherscan.io/address/0x7fA85f66e1Bfed5C559D85947566106683cC238D"
            target="_blank"
            rel="noreferrer"
          >
            Etherscan
          </a>
        </div>
        <div className="mx-1 md:mx-[90px]">
          <a
            // href="https://discord.gg/7BTPysN52Z"
            target="_blank"
            rel="noreferrer"
          >
            Forums
          </a>
        </div>
      </div>
    </div>
  );
}
