import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useAudio } from 'react-use';

import homepageBgm from '@/assets/audio/Theme_of_GRANDIA.mp3';
import musicArmImg from '@/assets/images/music/music_arm.svg';
import musicLogo from '@/assets/images/music/music_logo.svg';
import musicPlayImg from '@/assets/images/music/music_play.svg';
import musicStopImg from '@/assets/images/music/music_stop.svg';
import musicSymbol from '@/assets/images/music/music_symbol.svg';

import styles from './MusicArea.module.less';

interface MusicAreaProps {
  className?: string;
}

export function MusicArea(props: MusicAreaProps) {
  const { className } = props;
  const [audio, state, controls, ref] = useAudio({
    src: homepageBgm,
    autoPlay: false,
    loop: true,
    preload: 'auto'
  });

  const [isCloseAutoMusic, setIsCloseAutoMusic] = useState<boolean>(false);

  const handleSwitchPlay = useCallback(() => {
    if (state.playing) {
      controls.pause();
      setIsCloseAutoMusic(true);
      // console.log('play pause');
    } else {
      void controls.play()?.then(() => {
        console.log('play start');
      });
    }
  }, [controls, state.playing]);

  useEffect(() => {
    const audioElement = ref.current;
    if (!audioElement) return;

    const handleScroll = () => {
      if (!isCloseAutoMusic) {
        void controls.play();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [controls, isCloseAutoMusic, ref]);

  return (
    <div
      className={cn(styles.MusicArea, className, 'select-none')}
      onClick={handleSwitchPlay}
    >
      {audio}
      <img
        className={state.playing ? styles.stopImg : styles.playImg}
        src={state.playing ? musicStopImg : musicPlayImg}
        alt="play img"
        draggable={false}
      />
      {/*  <img
        className={cn(styles.circle, state.playing ? styles.circleLoading : '')}
        src={musicCircleImg}
        alt="music circle"
      /> */}
      <img
        className={cn(
          styles.musicArm,
          state.playing ? styles.armUnConnected : styles.armConnected
        )}
        src={musicArmImg}
        alt="music arm img"
        draggable={false}
      />
      <img
        className={styles.musicSymbol}
        src={musicSymbol}
        alt="music symbol"
        draggable={false}
      />

      <img
        className={styles.musicLogo}
        src={musicLogo}
        alt="music logo"
        draggable={false}
      />
    </div>
  );
}
