import React, { useContext, useEffect, useState } from 'react';

import { productService } from '@/libs/contractService';
import { Web3Context } from '@/libs/web3Store/context';

interface TreasuryType {
  treasuryBalance: string;
  treasuryAddress: string;
}

export function useTreasury() {
  const { getUserBalance } = useContext(Web3Context);
  const [treasury, setTreasury] = useState<TreasuryType>({
    treasuryBalance: '',
    treasuryAddress: ''
  });

  const [updateNumber, setUpdateNumber] = useState<number>(0);

  useEffect(() => {
    const getTreasury = async () => {
      const address = await productService.getAuction()?.treasury();
      if (address) {
        // const response = await getUserBalance(address);
        const response = await productService
          .getProvider()
          ?.getBalance(address);
        if (response) {
          setTreasury({
            treasuryAddress: address,
            treasuryBalance: response.toString()
          });
        }
      }
    };

    void getTreasury();
  }, [getUserBalance, updateNumber]);

  return { treasury, updateNumber, setUpdateNumber };
}
