/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  AuctionBidEvent,
  AuctionCreatedEvent
} from '@white-matrix/grandlinedao-sdk/dist/contracts/contracts/auction/Auction';
import { Button, Input, notification, Tooltip } from 'antd';
import cn from 'classnames';
import { log } from 'console';
import { ethers, utils } from 'ethers';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import arrow from '@/assets/images/arrow.png';
import arrowDisable from '@/assets/images/arrow_disable.png';
import grantLineLogo from '@/assets/images/grant-line-logo@2x.png';
import textImg from '@/assets/images/text@2x.png';
import turn from '@/assets/images/turn@2x.png';
import { FirstIsLandModal } from '@/components/Homepage/FirstIsLandModal';
import { MapLands } from '@/components/Homepage/Map';
import { OctopusModal } from '@/components/Homepage/OctopusModal';
import { TextArea } from '@/components/Homepage/TextArea';
import { ViewAllBidsModal } from '@/components/Homepage/ViewAllBidsModal';
import { useTranslation } from '@/i18n';
import { productService } from '@/libs/contractService';
import { Web3Context } from '@/libs/web3Store/context';
import {
  calculateAuctionTimes,
  truncateNumber,
  walletAddressEllipsis
} from '@/utils/common';
import { useAnchor } from '@/utils/hooks/useAnchor';
import { useDeviceSize } from '@/utils/hooks/useDeviceSize';
import { useTimeCountDown } from '@/utils/hooks/useTimeCountDown';
import { useTreasury } from '@/utils/hooks/useTreasury';
import { useWowAnimateSync } from '@/utils/hooks/useWowAnimateSync';

import styles from './index.module.less';

interface homeProps {
  className?: string;
}

interface DetailType {
  name: string;
  image: string;
  description: string;
}

interface AuctionType {
  endTime: number;
  highestBid: string;
  highestBidder: string;
  settled: boolean;
  startTime: number;
  tokenId: number;
}

function Home(props: homeProps) {
  const { className } = props;
  const { t } = useTranslation();

  useWowAnimateSync();
  useAnchor();

  const [modal1Open, setModal1Open] = useState<boolean>(false);
  const [modal2Open, setModal2Open] = useState<boolean>(false);
  const [auction, setAuction] = useState<AuctionType>({
    endTime: 0,
    highestBid: '',
    highestBidder: '',
    settled: false,
    startTime: 0,
    tokenId: 0
  });
  const [isOpenAllBids, setIsOpenAllBids] = useState<boolean>(false);
  const { leftDays, leftHours, leftMinutes, leftSeconds, isOutOfDate } =
    useTimeCountDown(auction.endTime * 1000);
  const [tokenId, setTokenId] = useState<number>();
  const [detail, setDetail] = useState<DetailType>();
  const [inputValue, setInputValue] = useState<string>('');
  const [reloadNumber, setReloadNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [imgLoading, setImageLoading] = useState<boolean>(false);
  const { walletInfo } = useContext(Web3Context);
  const { isSmallDevice, width } = useDeviceSize(867);

  const [historyList, setHistoryList] = useState<AuctionBidEvent[]>([]);
  const [createAuctionList, setCreateAuctionList] = useState<
    AuctionCreatedEvent[]
  >([]);
  const [prevAuctionList, setPrevAuctionList] = useState<AuctionCreatedEvent[]>(
    []
  );
  const { setUpdateNumber, updateNumber } = useTreasury();

  const toBidOrSettle = useCallback(async () => {
    if (!walletInfo.address) {
      return notification.info({ message: 'please connect wallet' });
    }
    try {
      setLoading(true);
      if (isOutOfDate) {
        const auction = productService.getAuction();

        const gas =
          await auction?.estimateGas.settleCurrentAndCreateNewAuction();
        const settle = await auction?.settleCurrentAndCreateNewAuction({
          gasLimit: gas?.mul(13).div(10)
        });

        if (settle) {
          await settle.wait(1);
          setReloadNumber(reloadNumber + 1);
          setUpdateNumber(updateNumber + 1);
        }
      } else {
        if (!inputValue || tokenId === undefined) {
          setLoading(false);
          return;
        }

        const auction = productService.getAuction();
        const gas = await auction?.estimateGas?.createBid(tokenId, {
          value: utils.parseEther(inputValue)
        });

        const createBid = await productService
          .getAuction()
          ?.createBid(tokenId, {
            value: utils.parseEther(inputValue),
            gasLimit: gas?.mul(13).div(10)
          });

        if (createBid) {
          await createBid.wait(1);
          setReloadNumber(reloadNumber + 1);
          setInputValue('');
        }
      }
    } catch (e) {
      console.log('e', (e as Error).message);
      if ((e as Error).message.includes('cannot estimate gas')) {
        return notification.error({ message: t('error_timeout') });
      }

      if ((e as Error).message.includes('insufficient funds')) {
        return notification.error({ message: t('error_balance') });
      }

      if ((e as Error).message.includes('user rejected transaction')) {
        return;
      }
      notification.error({ message: (e as Error).message });
    } finally {
      setLoading(false);
    }
  }, [
    inputValue,
    isOutOfDate,
    reloadNumber,
    setUpdateNumber,
    t,
    tokenId,
    updateNumber,
    walletInfo.address
  ]);

  useEffect(() => {
    const getTokenIdAndAuction = async () => {
      const data = await productService.getProductPool()?.totalSupply();
      const response = await productService.getAuction()?.auction();

      if (data) {
        setTokenId(
          Number(data.toString()) >= 1
            ? Number(data.toString()) - 1
            : Number(data.toString())
        );
      }
      if (response) {
        setAuction({
          endTime: response.endTime,
          highestBid: response.highestBid.toString(),
          highestBidder: response.highestBidder,
          settled: response.settled,
          startTime: response.startTime,
          tokenId: Number(response.tokenId.toString())
        });
      }
    };

    void getTokenIdAndAuction();
  }, [reloadNumber]);

  useEffect(() => {
    if (tokenId === undefined) return;
    setImageLoading(true);
    void productService
      .getProductPool()
      ?.tokenURI(tokenId)
      .then((data) => {
        const jsonData = data.split(',')[1];
        const decodeData = window.atob(jsonData);
        const detail = JSON.parse(decodeData.replace(/[\r\n]/g, ''));
        setDetail(detail as DetailType);
      })
      .catch((e) => console.log('error', e))
      .finally(() => setImageLoading(false));
  }, [tokenId]);

  useEffect(() => {
    if (!tokenId) return;
    const filter = productService.getAuction()?.filters.AuctionBid(tokenId);
    const createAuctionFilter = productService
      .getAuction()
      ?.filters.AuctionCreated();

    if (filter) {
      const getEvent = async () => {
        const result = await productService.getAuction()?.queryFilter(filter);

        setHistoryList(result || []);
      };

      void getEvent();
    }

    if (createAuctionFilter) {
      const getCreateAuctionEvent = async () => {
        const createAuctionResult = await productService
          .getAuction()
          ?.queryFilter(createAuctionFilter);
        const list = createAuctionResult?.filter(
          (item) => Number(item.args.tokenId) === tokenId
        );
        const prevList = createAuctionResult?.filter(
          (item) => Number(item.args.tokenId) === tokenId - 1
        );

        setCreateAuctionList(list || []);
        setPrevAuctionList(prevList || []);
      };

      void getCreateAuctionEvent();
    }
  }, [tokenId, reloadNumber]);

  return (
    <>
      <div
        className={cn(
          className,
          'w-screen overflow-x-hidden  bg-[#45B5D5] font-Zpix'
        )}
        style={{
          background: 'linear-gradient(to bottom, #45B5D5, #B1EEFD)'
        }}
      >
        {!isSmallDevice && <div className={styles.firstScreenCloud} />}
        <div className="relative w-screen overflow-x-hidden">
          <div
            className={cn(
              'flex items-center justify-center ',
              isSmallDevice
                ? 'flex-col pt-[30px]'
                : 'flex-row px-[118px] pt-[90px]'
            )}
          >
            <div
              className={cn(
                'flex  justify-center',
                isSmallDevice ? 'relative w-full pt-[100%]' : 'w-[50%]'
              )}
            >
              <div
                className={cn(
                  styles.dao,
                  'flex  items-center justify-center  ',
                  isSmallDevice
                    ? 'absolute left-0 top-0 h-full w-full bg-[yellow]'
                    : 'h-[300px] w-[300px] rounded-[20px] xl:h-[415px] xl:w-[415px]'
                )}
              >
                {imgLoading ? (
                  <div className={styles.loading} />
                ) : (
                  <img
                    src={detail?.image}
                    draggable={false}
                    className={cn(
                      isSmallDevice
                        ? 'h-full'
                        : 'h-[300px] rounded-[20px] xl:h-[415px]'
                    )}
                  />
                )}
              </div>
            </div>

            <div
              className={cn(
                ' rounded-[2px]',
                isSmallDevice ? 'w-full' : 'w-[50%] p-[15px] pb-[30px]'
              )}
            >
              {!isSmallDevice && (
                <div
                  className={cn(
                    'flex h-[22px] items-center ',
                    isSmallDevice ? 'px-2' : 'px-10'
                  )}
                >
                  <div
                    className={cn(
                      'flex h-6 w-6 select-none items-center justify-center rounded-[100%] bg-[#FFFFFF]',
                      tokenId !== undefined &&
                        tokenId >= 1 &&
                        'cursor-pointer hover:scale-[0.9] hover:bg-[#C5C5C5]'
                    )}
                    onClick={() => {
                      if (tokenId === undefined || tokenId < 1) return;

                      setTokenId(tokenId - 1);
                    }}
                  >
                    <img
                      className="h-[10px]"
                      src={
                        tokenId === undefined || tokenId < 1
                          ? arrowDisable
                          : arrow
                      }
                      draggable={false}
                    />
                  </div>

                  <div
                    className={cn(
                      'ml-4 flex h-6 w-6 select-none items-center justify-center rounded-[100%] bg-[#FFFFFF]',
                      tokenId !== undefined &&
                        tokenId < auction.tokenId &&
                        'cursor-pointer hover:scale-[0.9] hover:bg-[#C5C5C5]'
                    )}
                    onClick={() => {
                      if (tokenId === undefined || tokenId >= auction.tokenId)
                        return;
                      setTokenId(tokenId + 1);
                    }}
                  >
                    <img
                      className="h-[10px] rotate-180 "
                      src={
                        tokenId === undefined || tokenId >= auction.tokenId
                          ? arrowDisable
                          : arrow
                      }
                      draggable={false}
                    />
                  </div>

                  <div className="ml-[20px] font-Zpix text-[19px] leading-[18px] text-[#000000]">
                    {createAuctionList[0]
                      ? new Date(
                          Number(createAuctionList[0].args.startTime) * 1000
                        ).toLocaleDateString('en-US', {
                          timeZone: 'Asia/Shanghai',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })
                      : prevAuctionList[0]
                      ? new Date(
                          Number(prevAuctionList[0].args.startTime) * 1000
                        ).toLocaleDateString('en-US', {
                          timeZone: 'Asia/Shanghai',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })
                      : ''}
                  </div>
                </div>
              )}
              <div
                className={cn(
                  'min-h-[389px]  bg-[#9BDBED55] pb-10 pt-[4px]',
                  isSmallDevice ? 'mt-6 px-4' : 'mt-[10px] rounded-[10px] px-10'
                )}
                style={{ boxShadow: '0px 1px 6px #50BBD7' }}
              >
                {isSmallDevice && (
                  <div
                    className={cn(
                      'my-3 flex h-[22px] items-center',
                      isSmallDevice ? 'px-2' : 'px-10'
                    )}
                  >
                    <div
                      className={cn(
                        'flex h-6 w-6 select-none items-center justify-center rounded-[100%] bg-[#FFFFFF]',
                        tokenId !== undefined &&
                          tokenId >= 1 &&
                          'cursor-pointer hover:scale-[0.9] hover:bg-[#C5C5C5]'
                      )}
                      onClick={() => {
                        if (tokenId === undefined || tokenId < 1) return;

                        setTokenId(tokenId - 1);
                      }}
                    >
                      <img
                        className="h-[10px]"
                        src={
                          tokenId === undefined || tokenId < 1
                            ? arrowDisable
                            : arrow
                        }
                        draggable={false}
                      />
                    </div>

                    <div
                      className={cn(
                        'ml-4 flex h-6 w-6 select-none items-center justify-center rounded-[100%] bg-[#FFFFFF]',
                        tokenId !== undefined &&
                          tokenId < auction.tokenId &&
                          'cursor-pointer hover:scale-[0.9] hover:bg-[#C5C5C5]'
                      )}
                      onClick={() => {
                        if (tokenId === undefined || tokenId >= auction.tokenId)
                          return;
                        setTokenId(tokenId + 1);
                      }}
                    >
                      <img
                        className="h-[10px] rotate-180 "
                        src={
                          tokenId === undefined || tokenId >= auction.tokenId
                            ? arrowDisable
                            : arrow
                        }
                        draggable={false}
                      />
                    </div>

                    <div className="ml-[20px] font-Zpix text-[19px] leading-[18px] text-[#000000]">
                      {auction.startTime
                        ? new Date(auction.startTime * 1000).toLocaleDateString(
                            'en-US',
                            {
                              timeZone: 'Asia/Shanghai',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            }
                          )
                        : ''}
                    </div>
                  </div>
                )}
                <div
                  className={cn(
                    'font-Furore text-[43px]',
                    isSmallDevice ? '' : 'mt-5'
                  )}
                >
                  {t('pfpname', { count: tokenId })}
                </div>

                <div
                  className={cn(
                    'flex font-Zpix',
                    width <= 415 || (width >= 867 && width <= 1120)
                      ? 'mt-6 flex-col'
                      : 'mt-9 flex-row'
                  )}
                >
                  <div>
                    <div className="text-[19px]">
                      {tokenId !== undefined && tokenId < auction.tokenId
                        ? t('auction_winner')
                        : t('auction_currentbid')}
                    </div>
                    {tokenId !== undefined && tokenId < auction.tokenId ? (
                      <div className="mt-4 text-[34px]">
                        ≡{' '}
                        {historyList.length >= 1
                          ? truncateNumber(
                              Number(
                                historyList[
                                  historyList.length - 1
                                ].args.amount.toString()
                              ) / 1e18,
                              2
                            )
                          : 'n/a'}
                      </div>
                    ) : (
                      <div className="mt-4 text-[34px]">
                        ≡{' '}
                        {historyList.length >= 1
                          ? truncateNumber(
                              Number(
                                historyList[
                                  historyList.length - 1
                                ].args.amount.toString()
                              ) / 1e18,
                              2
                            )
                          : '0'}
                      </div>
                    )}
                  </div>
                  <div
                    className={cn(
                      width <= 415 || (width >= 867 && width <= 1120)
                        ? 'mt-6'
                        : 'ml-16'
                    )}
                  >
                    <div className="text-[19px]">
                      {tokenId !== undefined && tokenId < auction.tokenId
                        ? t('auction_holder')
                        : t('auction_auctiontime')}
                    </div>
                    {tokenId !== undefined && tokenId < auction.tokenId ? (
                      <div className="mt-4 text-[34px]">
                        {historyList.length >= 1
                          ? walletAddressEllipsis(
                              historyList[historyList.length - 1].args.bidder
                            )
                          : 'GrandLineDao'}

                        {/* <Tooltip title={treasuryAddress}>
                            {walletAddressEllipsis(treasuryAddress)}
                          </Tooltip> */}
                      </div>
                    ) : (
                      <div className="mt-4 text-[34px]">
                        {leftDays ? `${leftDays}D` : ''} {leftHours}h{' '}
                        {leftMinutes}m {leftSeconds}s
                      </div>
                    )}
                  </div>
                </div>

                {tokenId === auction.tokenId && (
                  <div className="mt-5 flex h-[60px]">
                    {!isOutOfDate && (
                      <Input
                        disabled={isOutOfDate}
                        value={inputValue}
                        className="h-[100%] text-[18px]"
                        placeholder={`≡ ${
                          Number(auction.highestBid)
                            ? truncateNumber(
                                (Number(auction.highestBid) / 1e18) * 1.1,
                                4
                              )
                            : '0.15 eth'
                        } or more`}
                        onChange={(event) => setInputValue(event.target.value)}
                      />
                    )}

                    <Button
                      loading={loading}
                      type="primary"
                      className={cn(
                        'ml-2 h-[100%] bg-[#1B62FA] text-[19px]',
                        isOutOfDate && 'ml-0 w-full'
                      )}
                      onClick={() => void toBidOrSettle()}
                    >
                      {isOutOfDate ? t('auction_settle') : t('auction_bid')}
                    </Button>
                  </div>
                )}

                {historyList.length >= 1 ? (
                  <div className="font mt-5  flex items-center justify-between font-Zpix text-[19px]">
                    <span>
                      {walletAddressEllipsis(
                        historyList[historyList.length - 1].args.bidder
                      )}
                    </span>
                    <div className="flex items-center">
                      <Tooltip
                        title={`${
                          Number(
                            historyList[
                              historyList.length - 1
                            ].args.amount.toString()
                          ) / 1e18
                        } ETH`}
                      >
                        <div>
                          ≡{' '}
                          {truncateNumber(
                            Number(
                              historyList[
                                historyList.length - 1
                              ].args.amount.toString()
                            ) / 1e18,
                            2
                          )}
                        </div>
                      </Tooltip>
                      <img
                        src={turn}
                        draggable={false}
                        className="ml-4 h-8 cursor-pointer"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_ETHER_SCAN_URL}/tx/${
                              historyList[historyList.length - 1]
                                .transactionHash
                            }`
                          )
                        }
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {tokenId === 0 && (
                  <div className="mt-10 font-Zpix text-[19px]">
                    {t('pfpdesctoken0')}
                  </div>
                )}

                {tokenId !== undefined &&
                  tokenId !== 0 &&
                  tokenId % 10 === 0 && (
                    <div className="mt-10 font-Zpix text-[19px]">
                      {t('pfpdesctoken10')}
                    </div>
                  )}

                {tokenId !== undefined &&
                  tokenId !== 0 &&
                  tokenId % 10 !== 0 &&
                  historyList.length >= 1 && (
                    <div
                      className="mt-10 cursor-pointer text-center font-Zpix text-[19px]"
                      onClick={() => setIsOpenAllBids(true)}
                    >
                      {t('auction_bidlist')}
                    </div>
                  )}

                {tokenId !== undefined &&
                  tokenId !== 0 &&
                  tokenId < auction.tokenId &&
                  tokenId % 10 !== 0 &&
                  historyList.length < 1 && (
                    <div
                      className="mt-10 cursor-pointer font-Zpix text-[19px]"
                      onClick={() => setIsOpenAllBids(true)}
                    >
                      {t('pfpDescTokenNull')}
                    </div>
                  )}
              </div>
            </div>
          </div>
          {isSmallDevice && <TextArea />}
          <div
            className={cn(
              'flex items-center justify-center',
              isSmallDevice ? 'flex-col pb-6' : 'flex-row'
            )}
          >
            <img
              src={grantLineLogo}
              className={cn(isSmallDevice ? 'w-[80%]' : 'w-[40%]')}
              draggable={false}
            />
            <img
              src={textImg}
              className={cn(isSmallDevice ? 'w-[80%]' : 'w-[40%]')}
              draggable={false}
            />
          </div>
          {/* <img
            className="absolute left-0 top-[220px] z-[0] w-[100%]"
            src={cloudLogo}
          /> */}
        </div>
      </div>

      <MapLands setModal1Open={setModal1Open} setModal2Open={setModal2Open} />

      {!isSmallDevice && (
        <OctopusModal isOpen={modal1Open} setIsOpen={setModal1Open} />
      )}

      {!isSmallDevice && (
        <FirstIsLandModal isOpen={modal2Open} setIsOpen={setModal2Open} />
      )}

      {historyList.length >= 1 && (
        <ViewAllBidsModal
          isOpen={isOpenAllBids}
          setIsOpen={setIsOpenAllBids}
          bidsList={historyList}
          daoImage={detail?.image || ''}
        />
      )}
    </>
  );
}

export default Home;
