import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { productService } from '@/libs/contractService';

import styles from './AllBidDetails.module.less';

interface AllBidDetailsProps {
  className?: string;
  blockNumber: number;
}

export function AllBidDetails(props: AllBidDetailsProps) {
  const { className, blockNumber } = props;
  const [time, setTime] = useState<string>('');

  useEffect(() => {
    async function getTime() {
      const result = await productService.getProvider()?.getBlock(blockNumber);
      if (result) {
        setTime(
          new Date(Number(result?.timestamp) * 1000).toLocaleString('en-US', {
            timeZone: 'Asia/Shanghai',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          })
        );
      }
    }

    void getTime();
  }, [blockNumber]);

  return <div className={cn(styles.AllBidDetails, className)}>{time}</div>;
}
